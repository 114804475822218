const defaultPaintProduct = {
  id: '_generic',
  handle: '_generic',
  title: 'BACKDROP PAINT',
  priceRange: {
    maxVariantPrice: {
      currencyCode: 'USD',
      amount: '45.0',
    },
  },
  images: {
    edges: [
      {
        node: {
          originalSrc:
            'https://cdn.shopify.com/s/files/1/0051/6295/8946/t/2/assets/can-generic.png?15168478235785077730',
          transformedSrc:
            'https://cdn.shopify.com/s/files/1/0051/6295/8946/t/2/assets/can-generic.png?15168478235785077730',
        },
      },
    ],
  },
};

export default defaultPaintProduct;
