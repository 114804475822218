import React, { memo } from 'react';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
import { Button, Stack, Box } from 'grommet';
import { isNil } from '../../lib/nodash';
import styled from 'styled-components';

import Loading from '../Loading';

const StyledDebounceInput = styled(DebounceInput)`
  opacity: ${(p) => (p.adjusting ? 0 : p.disabled ? 0.5 : 1)};
  width: 30px;
  height: 30px;
  position: relative;
  background-color: #f6f6f6;
  padding: 0;
  padding: 0 0 1px;
  border: 0;
  margin: 0 auto;
  display: block;
  height: 100%;
  text-align: center;
  color: $black;
  font-size: em(15px);

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: 1px dotted rgba(0, 0, 0, 0.1);
    outline-offset: 2px;
    color: $black;
  }
`;

const ButtonControl = styled(Button)`
  border: 0;
  border-radius: 0;
  background: none;
  padding: 0;
  margin: 0;
  outline: none;
  appearance: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 28px;
  cursor: pointer;
  display: block;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  padding: 0;

  svg {
    width: 100%;
    height: 100%;
    fill: ${(p) => p.theme.global.colors.black};
    stroke: #fff;
    stroke-width: 1px;
  }

  &--decrease {
    left: 0;
  }
`;

const InputNumberAdjust = ({
  quantity,
  onChange,
  disabled = false,
  disableInputEntry = false,
  min = 0,
  max,
  adjusting,
}) => {
  const canIncrease = isNil(max) ? true : quantity < max;
  const canDecrease = quantity > min;
  return (
    <Box
      width="110px"
      height="30px"
      className="cartcard__qty"
      style={{ position: 'relative' }}
    >
      <Stack fill>
        <StyledDebounceInput
          debounceTimeout={300}
          className="cartcard__qty-input"
          type="number"
          value={quantity}
          min="0"
          readOnly={disableInputEntry}
          aria-label="Item quantity"
          onChange={(e) => {
            onChange(parseInt(e.target.value));
          }}
          disabled={disabled}
        />
        {adjusting && (
          <Box fill align="center" justify="center">
            <Loading />
          </Box>
        )}
      </Stack>

      <ButtonControl
        className="cartcard__qty-btn cartcard__qty-btn--decrease"
        type="button"
        title="Decrease Quantity"
        data-qty={quantity}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChange(quantity - 1);
        }}
        disabled={disabled || !canDecrease}
        style={{ left: 0 }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
          <title>icon minus</title>
          <path d="M17.5,13.5H7.5v-2h10Z"></path>
        </svg>
      </ButtonControl>

      <ButtonControl
        className="cartcard__qty-btn cartcard__qty-btn--increase"
        type="button"
        title="Increase Quantity"
        data-qty={quantity}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChange(quantity + 1);
        }}
        disabled={disabled || !canIncrease}
        style={{ right: 0 }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
          <title>icon plus</title>
          <path d="M13.5,17.5h-2v-4h-4v-2h4v-4h2v4h4v2h-4Z"></path>
        </svg>
      </ButtonControl>
    </Box>
  );
};

export default memo(InputNumberAdjust);

InputNumberAdjust.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableInputEntry: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  adjusting: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
