import React, { useMemo, memo, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box, Heading, Text, ResponsiveContext } from 'grommet';
import { Group } from 'grommet-icons';
import {
  get,
  filter,
  map,
  keys,
  isEqual,
  uniqBy,
  isNil,
} from '../../lib/nodash';
import CartProductSuggestion from './CartProductSuggestion';
import CalcProductQuantityList from '../Calculator/CalcProductQuantityList';
import InputNumberAdjust from '../Inputs/InputNumberAdjust';
import CartRecommendedProductsSlider from './CartRecommendedProductsSlider';
import InfoToolTip from '../InfoToolTip';
import Loading from '../Loading';
import ButtonAddAllToCart from '../ButtonAddAllToCart';
import { useWindowSize } from '../useWindowSize';
import useCart from './useCart';
import { GET_SELECTED_AND_RECOMMENDED_PRODUCTS } from '../../queries/productQueries';

import deNodify from '../../lib/deNodify';
import mapOrder from '../../lib/cart/mapOrder';
import extractGid from '../../lib/extractGid';
import calculateCartDiscounts from '../../lib/cart/calculateCartDiscounts';
import selectReferralCode from '../../state/industry/selectReferralCode';
import { calculateRecommendedProductQuantities } from '../../lib/cart/cartRecommendations';
import { setPeople } from '../../state/calculator/calculatorSlice';
import { shopifyClient } from '../../gatsby-theme-apollo/client';

const CartRecommendedProducts = ({ cartItems, layout, onAddToCart }) => {
  const dispatch = useDispatch();
  const people = useSelector((state) => state.calculator.calcVariables.people);
  const { lineItems, samples, samplesOnly } = useCart();
  const industryReferralCode = useSelector(selectReferralCode);
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';
  const [windowWidth] = useWindowSize();
  const isCheckout = layout === 'checkout';
  const sliderLayout = isMobile || isCheckout;

  const { loading, data } = useQuery(GET_SELECTED_AND_RECOMMENDED_PRODUCTS, {
    variables: { productIds: [] },
    client: shopifyClient,
  });

  const items = get('recommendedTools.products.edges', data) || [];
  const essentialsKit = get('essentialsKit', data) || {};
  const supplyItems = [{ node: essentialsKit }, ...items];

  const activeDiscounts = useMemo(
    () =>
      calculateCartDiscounts.getActiveDiscounts(
        lineItems,
        !isNil(industryReferralCode)
      ),
    [items, industryReferralCode, lineItems]
  );

  const hasSuppliesDiscount =
    activeDiscounts.indexOf('paint-and-supply-discount') > -1;

  const supplyDiscount = hasSuppliesDiscount ? 0.1 : 0;

  const quantities = useMemo(
    () =>
      calculateRecommendedProductQuantities({
        supplyItems,
        givenItems: cartItems,
        samples,
        people,
        relativeToGiven: true,
      }),
    [people, cartItems, items]
  );

  const relativeQuantities = useMemo(
    () =>
      calculateRecommendedProductQuantities({
        supplyItems,
        givenItems: cartItems,
        samples,
        people,
        relativeToGiven: true,
      }),
    [people, cartItems, items, supplyItems, samples]
  );

  const isSuggestedQuantityFulfilled = useCallback(
    (x) => {
      const suggested = relativeQuantities[extractGid(x.id || x.node?.id)];
      return suggested === 0;
    },
    [relativeQuantities]
  );

  const recommendedProducts = uniqBy(
    (x) => x.node.gid,
    filter(
      (x) => keys(quantities).indexOf(get('node.gid', x)) > -1,
      map(
        (x) => ({ ...x, node: { ...x.node, gid: extractGid(x.node.id) } }),
        items
      )
    )
  ).sort(mapOrder(keys(quantities), 'node.gid'));

  const recommendedWithEssentialsKit = deNodify(
    uniqBy((x) => x.node.gid, [{ node: essentialsKit }, ...recommendedProducts])
  );

  const recommendedItems = recommendedWithEssentialsKit;

  const allSuggestedQuantitiesFulfilled = isEqual(
    0,
    filter(
      (x) => !isSuggestedQuantityFulfilled(x, items),
      recommendedWithEssentialsKit
    ).length
  );

  return (
    <Box>
      {loading ? (
        <Box fill pad="large" justify="center" align="center">
          <Loading size="large" />
        </Box>
      ) : (
        <Box>
          {!samplesOnly && (
            <Box
              direction="row"
              align="center"
              pad={{ vertical: 'medium', horizontal: 'medium' }}
              gap="1rem"
              justify="center"
            >
              <Group size="small" color="black" />
              <Text>Number of people painting:</Text>
              <InputNumberAdjust
                quantity={people}
                onChange={(qty) => dispatch(setPeople(qty))}
                disableInputEntry={true}
                min={1}
                max={3}
              />
            </Box>
          )}

          <Box
            direction="row-responsive"
            margin={{ vertical: 'small' }}
            gap={sliderLayout ? 'medium' : 'small'}
            align="start"
          >
            <Box
              pad={
                isCheckout
                  ? 'none'
                  : sliderLayout
                  ? { horizontal: 'medium' }
                  : { left: '20px', right: '0' }
              }
              basis={sliderLayout ? 'auto' : '50%'}
              gap={sliderLayout ? 'medium' : 'small'}
              wrap={false}
            >
              <Box>
                <Box direction="row" align="center" gap="xsmall">
                  <Heading level={4} margin="none">
                    What You&apos;ll Need{' '}
                  </Heading>
                  <InfoToolTip
                    color="black"
                    size="18px"
                    text="Supply recommendations are unique to your paint order and based on amount and type of paint and number of people painting with you."
                  />
                </Box>
                <CalcProductQuantityList
                  quantities={quantities}
                  itemData={supplyItems}
                  people={people}
                  cartItems={cartItems}
                  essentialsKitId={get('id', essentialsKit)}
                />
                {!allSuggestedQuantitiesFulfilled && (
                  <Box margin={{ top: 'medium' }}>
                    <ButtonAddAllToCart
                      quantityMap={relativeQuantities}
                      productData={map('node', supplyItems)}
                      primary={true}
                      size="small"
                      alignSelf="start"
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {!sliderLayout && (
              <Box basis="50%" pad={{ horizontal: 'medium' }}>
                {quantities[extractGid(get('id', essentialsKit))] > 0 && (
                  <CartProductSuggestion
                    productData={essentialsKit}
                    hero={true}
                    suggestedQuantity={
                      relativeQuantities[extractGid(get('id', essentialsKit))]
                    }
                    suggestedQuantityFulfilled={isSuggestedQuantityFulfilled(
                      essentialsKit
                    )}
                    discountPercentage={supplyDiscount}
                    isEssentialsKit={true}
                    hideDescription={true}
                    onAddToCart={onAddToCart}
                    imageStyle={{
                      position: 'relative',
                      left: '-15px',
                      marginBottom: '-13px',
                    }}
                  />
                )}
              </Box>
            )}
          </Box>

          {sliderLayout ? (
            <Box
              margin={{ top: recommendedItems.length > 0 ? 'medium' : 'none' }}
              width="100vw"
            >
              {recommendedProducts.length > 0 && (
                <CartRecommendedProductsSlider
                  items={recommendedItems}
                  relativeQuantities={relativeQuantities}
                  supplyDiscount={supplyDiscount}
                  isSuggestedQuantityFulfilled={isSuggestedQuantityFulfilled}
                  essentialsKitId={get('id', essentialsKit)}
                  onAddToCart={onAddToCart}
                  sliderWidth={isMobile ? windowWidth : undefined}
                  suppressPopoutOnAdd={true}
                />
              )}
            </Box>
          ) : (
            <Box
              direction="row-responsive"
              wrap={true}
              justify="between"
              margin={{
                vertical: recommendedItems.length > 0 ? 'medium' : 'none',
              }}
              pad={{ horizontal: 'medium' }}
            >
              {recommendedProducts.map((x) => (
                <Box
                  key={get('node.id', x)}
                  data-thing={get('node.id', x)}
                  margin={{ vertical: 'medium' }}
                  basis={'calc(100% / 3 - 7px)'}
                >
                  <CartProductSuggestion
                    plain={true}
                    size="small"
                    productData={get('node', x)}
                    discountPercentage={supplyDiscount}
                    suggestedQuantity={relativeQuantities[get('node.gid', x)]}
                    suggestedQuantityFulfilled={isSuggestedQuantityFulfilled(x)}
                    hideDescription={true}
                    suppressPopoutOnAdd={true}
                    onAddToCart={onAddToCart}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default memo(CartRecommendedProducts);

CartRecommendedProducts.propTypes = {
  cartItems: PropTypes.array.isRequired,
  layout: PropTypes.string,
  headingContent: PropTypes.node,
  onAddToCart: PropTypes.func,
};
