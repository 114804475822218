import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { get, isEqual } from '../../lib/nodash';
import CartProductSuggestion from './CartProductSuggestion';
import CartShopCollectionButtons from './CartShopCollectionButtons';
import ContentSlider from '../ContentSlider';
import extractGid from '../../lib/extractGid';

const ExtraSlide = () => (
  <CartShopCollectionButtons
    pad="medium"
    gap="medium"
    fill
    align="center"
    justify="center"
    background="#F0F0F0"
  />
);

const CartRecommendedProductsSlider = ({
  items,
  relativeQuantities,
  supplyDiscount,
  isSuggestedQuantityFulfilled,
  essentialsKitId,
  sliderWidth,
  suppressPopoutOnAdd = false,
  onAddToCart,
}) => {
  return (
    <>
      {items.length > 0 && (
        <ContentSlider
          params={{
            slidesPerView: '2.3',
            spaceBetween: 10,
            centeredSlides: false,
            width: sliderWidth ? sliderWidth : undefined,
          }}
          navColor="#000"
          hasCustomNav={true}
          extraSlides={[
            {
              component: ExtraSlide,
            },
          ]}
        >
          {items.map((x) => {
            const isKit = isEqual(
              extractGid(get('id', x)),
              extractGid(essentialsKitId)
            );

            return (
              <Box
                key={get('id', x)}
                flex={true}
                style={{ alignSelf: 'stretch' }}
                className="suggestion-container"
              >
                <CartProductSuggestion
                  plain={true}
                  size="small"
                  productData={x}
                  discountPercentage={supplyDiscount}
                  suggestedQuantityFulfilled={isSuggestedQuantityFulfilled(x)}
                  suggestedQuantity={
                    relativeQuantities[
                      extractGid(get('node.gid', x)) ||
                        extractGid(get('node.id', x))
                    ]
                  }
                  isEssentialsKit={isKit}
                  hideDescription={true}
                  imageMax={{
                    width: isKit ? '160px' : '100px',
                    height: '70px',
                  }}
                  suppressPopoutOnAdd={suppressPopoutOnAdd}
                  onAddToCart={onAddToCart}
                />
              </Box>
            );
          })}
        </ContentSlider>
      )}
    </>
  );
};

CartRecommendedProductsSlider.propTypes = {
  items: PropTypes.array.isRequired,
  relativeQuantities: PropTypes.object,
  supplyDiscount: PropTypes.number,
  isSuggestedQuantityFulfilled: PropTypes.func,
  essentialsKitId: PropTypes.string,
  sliderWidth: PropTypes.oneOfType([PropTypes.number]),
  suppressPopoutOnAdd: PropTypes.oneOfType([PropTypes.bool]),
  onAddToCart: PropTypes.func,
};

export default CartRecommendedProductsSlider;
