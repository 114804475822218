import React, { useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { get, includes, some, reject, head } from '../../lib/nodash';
import { Box, Stack, Text, Heading, ResponsiveContext } from 'grommet';

import findImage from '../../lib/findImage';
import { isSample, isFiveGallonQty, getProductPath } from '../../lib/product';
import formatCurrency from '../../lib/formatCurrency';
import extractGid from '../../lib/extractGid';
import deNodify from '../../lib/deNodify';
import sanitizeHtml from '../../lib/sanitizeHtml';
import ImagePlaceholder from '../ImagePlaceholder';
import IconCheck from '../Icons/IconCheck';
import ProductAddToCartForm from '../Product/ProductAddToCartForm';
import Loading from '../Loading';
import SmartLink from '../SmartLink';
import DiscountedPriceLabel from '../Product/DiscountedPriceLabel';
import { COLOR_PAINT_PRODUCT_TYPES } from '../../lib/productTypes';
import { SUGGESTED_PRODUCT_ADDED } from '../../lib/analytics/segmentActions';

const emptyProduct = {
  handle: '',
  title: '',
  variants: {
    edges: [{}],
  },
  images: { edges: [{}] },
};

const CartProductSuggestion = ({
  productData = emptyProduct,
  loadingProduct,
  suggestedQuantity = 1,
  preventAddToCartMessage,
  handleQuantityChange,
  discountPer = 0,
  discountPercentage = 0,
  largeImage,
  hideDescription = false,
  imageMax = { width: '100px' },
  size = 'medium',
  suggestedQuantityFulfilled,
  hero,
  imageStyle = {},
  onAddToCart,
  imageMatcher,
  isEssentialsKit = false,
  suppressPopoutOnAdd = false,
  ...rest
}) => {
  const viewportSize = useContext(ResponsiveContext);
  const isMobile = viewportSize === 'small';

  const product = productData;

  const productDescription = loadingProduct
    ? null
    : sanitizeHtml(get('descriptionHtml', product));
  const productVariant =
    head(reject(isSample, deNodify(get('variants', product)))) || {};
  const variantId = extractGid(productVariant.id);
  const variantPrice =
    get('price.amount', productVariant) ||
    get('priceV2.amount', productVariant);

  const discountedPrice = useMemo(
    () =>
      discountPercentage > 0
        ? variantPrice - variantPrice * discountPercentage
        : isFiveGallonQty(product)
        ? variantPrice - discountPer * 5
        : variantPrice - discountPer,
    [product]
  );
  const productPrice = formatCurrency(discountedPrice);
  const isKit = product.handle === 'essentials-kit';
  const gridImageUrl = useMemo(
    () =>
      findImage(
        get('images', product),
        imageMatcher ? imageMatcher : isKit ? '_hero' : '_grid'
      ),
    [product, imageMatcher, isKit]
  );

  const productPath = useMemo(() => getProductPath(product), [product]);

  const productTitle = isEssentialsKit
    ? 'The 11-piece Essentials Kit'
    : get('title', product);

  const isPaint = some(
    (t) => includes(product.productType, t),
    COLOR_PAINT_PRODUCT_TYPES
  );

  return (
    <Box
      flex={isMobile ? { grow: 1, shrink: 0 } : true}
      direction="column"
      justify="between"
      background="#f0f0f0"
      fill
      {...rest}
    >
      <Stack alignSelf="stretch" anchor="top-right" fill>
        <Box
          justify="between"
          flex={{ grow: 1 }}
          fill
          className="first-stack-box"
        >
          <Box
            direction={hero ? 'column-reverse' : 'column'}
            flex={{ grow: 1 }}
          >
            <Box align={hero ? 'start' : 'center'} justify="center">
              <Box
                justify={hero ? 'start' : 'center'}
                width={{
                  max: largeImage
                    ? '100%'
                    : size === 'small'
                    ? imageMax.width
                    : '180px',
                }}
              >
                {loadingProduct ? (
                  <Loading
                    size="large"
                    theme="light"
                    centered
                    dimension="180px"
                  />
                ) : (
                  <SmartLink to={productPath}>
                    {gridImageUrl ? (
                      <img
                        src={gridImageUrl}
                        alt={productTitle}
                        style={{
                          maxWidth: '100%',
                          maxHeight: imageMax.height ? imageMax.height : 'none',
                          ...imageStyle,
                        }}
                      />
                    ) : (
                      <ImagePlaceholder />
                    )}
                  </SmartLink>
                )}
              </Box>

              {!hideDescription && productDescription && !largeImage && (
                <p dangerouslySetInnerHTML={{ __html: productDescription }} />
              )}
            </Box>
            <Box
              className="title-and-description"
              flex={true}
              fill
              pad={hero ? {} : { horizontal: 'small' }}
            >
              <Box
                flex={{ grow: 1 }}
                pad={
                  largeImage
                    ? { horizontal: 'medium', top: 'medium' }
                    : undefined
                }
                gap="small"
                margin={{ top: size === 'small' ? 'small' : 'none' }}
                justify="center"
              >
                <Heading
                  level={size === 'small' ? 5 : 4}
                  margin="none"
                  size={isMobile ? 'small' : 'medium'}
                  textAlign={hero ? 'start' : 'center'}
                >
                  <SmartLink
                    style={{
                      fontWeight: 500,
                      display: 'block',
                      textDecoration: 'none',
                    }}
                    to={getProductPath(product)}
                  >
                    {productTitle}
                  </SmartLink>
                </Heading>
                {isPaint && <Text>in {product.productType}</Text>}
              </Box>
              <Box
                pad={{ vertical: hero ? 'none' : 'small' }}
                justify={hero ? 'start' : 'center'}
                direction="row"
                gap="small"
              >
                <Text>
                  {isFiveGallonQty(product) ? '5 Gallons ' : ''}
                  {productPrice}
                </Text>
                {discountPer > 0 ||
                  (discountPercentage > 0 && (
                    <DiscountedPriceLabel
                      fullPrice={variantPrice}
                      justify="center"
                      size="medium"
                    />
                  ))}
              </Box>
            </Box>
            <Box pad={{ horizontal: largeImage ? 'medium' : undefined }}>
              {!hideDescription && productDescription && largeImage && (
                <p dangerouslySetInnerHTML={{ __html: productDescription }} />
              )}
            </Box>
          </Box>
          <Box
            justify="stretch"
            pad={
              largeImage
                ? { horizontal: 'medium', bottom: 'medium' }
                : undefined
            }
          >
            {!loadingProduct && (
              <>
                <Box margin={{ top: 'small' }}>
                  <ProductAddToCartForm
                    product={product}
                    variantId={variantId}
                    suggestedQuantity={suggestedQuantity}
                    className="c-card__bar"
                    preventAddToCartMessage={preventAddToCartMessage}
                    onQuantityChange={handleQuantityChange}
                    direction="row-responsive"
                    buttonLabel={
                      isMobile
                        ? `Add ${
                            suggestedQuantity === 0 ? '' : suggestedQuantity
                          }`
                        : 'Add'
                    }
                    size="small"
                    showQuantitySelect={!isMobile}
                    onAddToCart={onAddToCart}
                    trackingEvent={SUGGESTED_PRODUCT_ADDED}
                    suppressPopoutOnAdd={suppressPopoutOnAdd}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box pad="small">
          {suggestedQuantityFulfilled && (
            <IconCheck color="black" size="16px" />
          )}
        </Box>
      </Stack>
    </Box>
  );
};

CartProductSuggestion.propTypes = {
  product: PropTypes.object,
  suggestedQuantity: PropTypes.number,
  suggestedQuantityFulfilled: PropTypes.bool,
  size: PropTypes.string,
  hideDescription: PropTypes.bool,
  preventAddToCartMessage: PropTypes.string,
  handleQuantityChange: PropTypes.func,
  discountPer: PropTypes.number,
  discountPercentage: PropTypes.number,
  largeImage: PropTypes.bool,
  imageMax: PropTypes.object,
  loadingProduct: PropTypes.bool,
  productData: PropTypes.object,
  hero: PropTypes.bool,
  imageStyle: PropTypes.object,
  onAddToCart: PropTypes.func,
  imageMatcher: PropTypes.string,
  isEssentialsKit: PropTypes.oneOfType([PropTypes.bool]),
  suppressPopoutOnAdd: PropTypes.bool,
};

export default memo(CartProductSuggestion);
